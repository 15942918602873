import DanielleWheeler from 'static/images/people/Danielle_Wheeler.webp'
import RileyWelch from 'static/images/people/Riley_Welch.webp'
import JoshuaHunter from 'static/images/people/Joshua_Hunter.webp'
import FranciscoErian from 'static/images/people/Francisco_Erian.webp'
import Frank from 'static/images/people/Frank.webp'
import Brent from 'static/images/people/Brent.webp'
import Natalie from 'static/images/people/natalie.webp'
import Tudor from 'static/images/people/tudor.webp'
import Ashleigh from 'static/images/people/ashleigh.webp'
import Shiela from 'static/images/people/shiela.webp'

import bestRelationship from 'static/images/g2_badges/best_relationship_fall_2023.svg'
// import bestResultsSummer from 'static/images/g2_badges/best_results_summer_2023.svg'
// import highPerformer from 'static/images/g2_badges/high_performer_fall_2023.svg'
// import momentumLeader from 'static/images/g2_badges/momentum_leader_fall_2023.svg'
// import bestUsabilitySmallBusiness from 'static/images/g2_badges/best_usability_small_business_fall_2023.svg'
// import bestSupportMidMarketSummer from 'static/images/g2_badges/best_support_mid_market_summer_2023.svg'
// import bestMeetReqMidMarketSummer from 'static/images/g2_badges/best_meets_requirements_mid_market_summer_2023.svg'
// import bestResultsSmallBizSummer from 'static/images/g2_badges/best_results_small_business_summer_2023.svg'
// import bestRelationshipSmallBizSummer from 'static/images/g2_badges/best_relationship_small_business_summer_2023.svg'

// import leader from 'static/images/g2_badges/leader_fall_2023.svg'
import bestUsability2024 from 'static/images/g2_badges/best_usability_2024.svg'
import highPerformer2024 from 'static/images/g2_badges/performer_2024.svg'
import momentumLeader2024 from 'static/images/g2_badges/momentum_2024.svg'
import recommend2024 from 'static/images/g2_badges/recommend_2024.svg'
import usersLove from 'static/images/g2_badges/users-love-us.svg'
import highestSatisfaction from 'static/images/g2_badges/highest-satisfaction.svg'
import bestMeetsRequirementsSmallBusiness2024 from 'static/images/g2_badges/best_req_smallbiz.svg'
import bestMeetsRequirementsMidMarket2024 from 'static/images/g2_badges/best_req_mid.svg'
import highPerformerEnterprise from 'static/images/g2_badges/high_performer_enterprise.svg'

export const G2_FEATURES = {
  title: 'Trusted by 26,000 brands and agencies, recognized among the best social media management tools on G2',
  badges: [
    { image: bestUsability2024, alt: 'G2 Best Usability Winter 2024' },
    { image: highPerformer2024, alt: 'G2 Performer Winter 2024' },
    { image: momentumLeader2024, alt: 'G2 Momentum Leader Winter 2024' },
    { image: usersLove, alt: 'Users love us!' },
    { image: highestSatisfaction, alt: 'Highest Satisfaction Products 2024' },
    // { image: leader, alt: 'G2 Leader Fall 2023' },
    { image: bestMeetsRequirementsSmallBusiness2024, alt: 'G2 Best Meets Requirements Small Business 2024' },
    { image: bestMeetsRequirementsMidMarket2024, alt: 'G2 Best Meets Requirements Mid Market 2024' },
    { image: highPerformerEnterprise, alt: 'G2 High Performer Enterprise 2024' },

    // { image: bestResultsSummer, alt: 'G2 Best Results Small Business Summer 2023' },
    // { image: bestSupportMidMarketSummer, alt: 'G2 Best Support Mid Market Summer 2023' },
  ],
}

export const ERROR_MESSAGE = 'Whoops! Something went wrong.'
export const INTEGRATION_TABS = [
  { pagePath: '/integrations/facebook', name: 'Facebook' },
  { pagePath: '/integrations/google', name: 'Google Business' },
  { pagePath: '/integrations/instagram', name: 'Instagram' },
  { pagePath: '/integrations/linkedin', name: 'LinkedIn' },
  { pagePath: '/integrations/opentable', name: 'Opentable' },
  { pagePath: '/integrations/pinterest', name: 'Pinterest' },
  { pagePath: '/integrations/reddit', name: 'Reddit' },
  { pagePath: '/integrations/snapchat', name: 'Snapchat' },
  { pagePath: '/integrations/tiktok', name: 'TikTok' },
  { pagePath: '/integrations/tripadvisor', name: 'TripAdvisor' },
  { pagePath: '/integrations/trustpilot', name: 'Trustpilot' },
  { pagePath: '/integrations/twitter', name: 'Twitter' },
  { pagePath: '/integrations/tumblr', name: 'Tumblr' },
  { pagePath: '/integrations/threads', name: 'Threads' },
  { pagePath: '/integrations/youtube', name: 'YouTube' },
  { pagePath: '/integrations/yelp', name: 'Yelp' },
].sort((item1, item2) => (item1.name > item2.name ? 1 : -1))

export const CONTAINER_MAX_WIDTH = '1280px'

export const API_URL = 'https://localhost:3000/api'

export const VISTA_SOCIAL_REVIEWS = [
  {
    description: `I can schedule all posts on all social media in one platform, which is a time saver :) It is user-friendly, and you can see everything there. Reports, messages, scheduled and published posts. I like the inbox part because I can check all the messages without leaving the platform. You can track everything in there. I also like how I can see all scheduled posts on it.`,
    author: {
      name: 'Shiela Villasica',
      position: 'Social Media Marketer for Business Owners',
      logo: Shiela,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-7538290',
  },
  {
    description: `All the features on this platform work really well. I like the looks of the content calender than any other platform I've used. The platform is easy to use, nothing too tech-savy or hard to navigate. The best part is the PRICE! $5 a profile with unlimited users!!! DAMN. Staying here forever.`,
    author: {
      name: 'Ashleigh Jarra',
      position: 'Founder of Creative Cacti',
      logo: Ashleigh,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-7606952',
  },
  {
    description: `Awesome social media content organizer and planner! All the features on this platform were easy to use, and the software's user experience caught my attention on the spot. The platform is straightforward to use, intuitive and simple to navigate. Lastly, the cherry on top is $5 per profile and unlimited users 😲!!!`,
    author: {
      name: 'Tudor Sorea',
      position: 'Founder at ResQ Digital',
      logo: Tudor,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-7608531',
  },
  {
    description: `I am OBSESSED! I am shocked at how quickly I created a post on IG! With the AI assistant on Vista Social, I only had to click "generate" once, and I was satisfied with the results! 10/10 recommend! I will be telling all of my followers on IG about it!`,
    author: {
      name: 'Natalie',
      position: 'Natalie Johnson Consulting',
      logo: Natalie,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-7673853',
  },
  {
    description: `Very complete feature set and excellent support. Especially publishing, reporting and engagement features for all supported networks. As well as curated content, asset library, best posting times and a lot more. That usually comes at a much more premium price. Their support is also quick to respond, open to feedback and honest about what's going on.`,
    author: {
      name: 'Frank Diepmaat',
      position: 'Founder at Hackatoria',
      logo: Frank,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-6980935',
  },

  {
    description: `Absolute game changer! If you are looking to manage all your clients social media accounts, in one location, with ease, then look no further than Vista Social. It has made my overall job as a social media marketer so much easier and efficient. I can't recommend Vista Social enough to anyone working in this industry.`,
    author: {
      name: 'Riley Welch',
      position: 'Owner at 92 Social',
      logo: RileyWelch,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-6571515',
  },

  {
    description: `As an agency owner, I'm always looking for new tools that allow WMA to plan, collaborate, and create content both for the agency and for our clients in the most efficient way possible... Vista Social has exactly what I've been looking for.`,
    author: {
      name: 'Danielle Wheeler',
      position: 'CEO at Wheeler Marketing Agency',
      logo: DanielleWheeler,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-6534621',
  },
  {
    description: `The ability to create and share content across social networks is perfect. It also gives me the ability to generate content from 3rd party sources as well as share videos and image assets easily.`,
    author: {
      name: 'Brent Peterson',
      position: 'President at Wagento Commerce',
      logo: Brent,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-7119395',
  },
  {
    description: `The ability to manage all my client social profiles in one location and switch between them is amazing. Connecting new profiles and scheduling posts is super easy to do, compared to other tools I used. Reports I create for my clients are simply stunning!`,
    author: {
      name: 'Joshua Hunter-Atencia',
      position: 'Founder at The Venandi Group',
      logo: JoshuaHunter,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews',
  },
  {
    description: `Vista Social is a complete tool to organize the daily work and mid/long-term social media campaigns. It's very easy and intuitive, with great features like full support for TikTok and Instagram, user tagging, inbox, automatic reports, review management and listening.`,
    author: {
      name: 'Francisco Erian',
      position: 'Digital Content at Acequia',
      logo: FranciscoErian,
    },
    count: 5,
    link: 'https://www.g2.com/products/vista-social/reviews/vista-social-review-6545182 ',
  },
]

const BOOST_FAQ = {
  id: 'boost',
  title: 'Can I boost posts using Vista Social?',
  description:
    'Yes! You can automatically boost posts across Facebook, Instagram, LinkedIn, TikTok and Twitter right from Vista Social. Multiple boost settings per social profile enable users to easily target different audiences and test results.',
}

const INSTAGRAM_FAQ = {
  id: 'instagram',
  title: 'Does Vista Social have direct Instagram publishing?',
  description:
    'Yes! You can schedule reels, videos and carousels with up to 10 images and videos to Instagram. You can also tag users, products and locations in your posts. Grid preview is available to better plan your content. Automatically post up to 5 comments.',
}

const FACEBOOK_FAQ = {
  id: 'facebook',
  title: 'Does Vista Social have direct Facebook publishing?',
  description:
    'Yes! You can schedule reels, videos, images, carousels and slideshows to Facebook pages and groups. Location tagging, posting additonal comments and organic targetting is also avaialble for Facebook.',
}

const TIKTOK_FAQ = {
  id: 'tiktok',
  title: 'Does Vista Social have direct TikTok publishing?',
  description:
    'Yes! You can schedule images and videos to TikTok business and creator fund profiles. No extra apps to install. No reminders. Videos are uploaded and published straight to TikTok profiles using official TikTok API.',
}

const MOBILE_FAQ = {
  id: 'mobile',
  title: 'Does Vista Social have a mobile app?',
  description:
    'Yes! We have a fully featured mobile app for both <a href="https://apps.apple.com/jm/app/vista-social/id1623203443">IOS</a> and <a href="https://play.google.com/store/apps/details?id=com.vistasocial.android">Android</a> that you can download and use for scheduling of content!',
}

const BULK_FAQ = {
  id: 'bulk',
  title: 'Does Vista Social have bulk scheduling options?',
  description:
    'Vista Social offers a comprehensive suite of bulk scheduling options from CSV uploads, imports from a blog, content discovery, and bulk upload of images and videos.',
}

export const FREQUENTLY_ASKED_QUESTIONS = {
  advocacy: [
    {
      id: 'accounts',
      title: 'Is it possible to connect my Vista Social account with the Employee Advocacy tool?',
      description:
        'Yes! Your Vista Social account has Employee Advocacy embedded. All scheduled posts in your Vista account can be routed into Employee Advocacy as shareable content.',
    },

    {
      id: 'networks',
      title: 'What social networks can my employees share our content on?',
      description:
        "Vista Social supports all major social networks: Facebook, Instagram, TikTok, Threads, X (Twitter), LinkedIn, Pinterest, Reddit, etc. For complete list please visit <a href='/integrations'>integration page</a>.",
    },
    {
      id: 'accounts',
      title: 'What actions can employees take?',
      description:
        'Employees can like, comment, reshare and share content. This makes participation in advocacy that much easier. While resharing is probably the best impactful engagement option, a simple click of a button will get an employee to like a post which in many ways is just as valuable.',
    },
    {
      id: 'accounts',
      title: 'Can employees like and comment on posts?',
      description:
        "Yes! Vista Social's Employee Advocacy allows users to not only share content but also like, comment and reshare it. ",
    },
    {
      id: 'mobile',
      title:
        "Can my team members share content through Vista's Employee Advocacy tool without being logged into a work computer?",
      description:
        'Yes, your team can share from their phone, computer or our mobile app that makes it easy to share content as soon as it’s discovered. When you’re not on the go, our desktop experience is a great way to find and share great content.',
    },

    {
      id: 'mobile',
      title: "How can my company measure the success of our efforts with Vista's Employee Advocacy tool?",
      description:
        'You’ll get insights into key metrics that help demonstrate the success of your efforts including — adoption rate, active participation, top contributors, organic reach, organic engagement and referrals.',
    },

    {
      id: 'security',
      title: "Does Vista's Employee Advocacy tool support 2 factor authentication?",
      description: 'Yes, all users can setup 2FA using an authenticator app of their choice.',
    },
  ],
  tumblr: [
    {
      id: 'pages',
      title: 'Can I directly schedule to multiple Tumblr Profiles?',
      description:
        'Yes, you can connect as many Tumblr profiles as you like to your Vista Social account and schedule your posts.',
    },
    {
      id: 'videos',
      title: 'Can I directly schedule video posts to Tumblr using Vista Social?',
      description:
        'Yes, you can publish single and multiple video posts to Tumblr profiles right from your Vista Social account.',
    },
    {
      id: 'images',
      title: 'Can I directly schedule image posts to Tumblr using Vista Social?',
      description:
        'Yes, you can publish single and multiple image posts to Tumblr profiles right from your Vista Social account.',
    },
    {
      id: 'comments',
      title: 'Can I respond to comments on Tumblr Profiles using Vista Social?',
      description: 'Yes, you can respond to comments on posts in your Tumblr posts using Vista Soical!',
    },
    {
      id: 'reports',
      title: 'Can I generate reports and understand performance of my Tumblr Profiles using Vista Social?',
      description:
        'Yes, we offer a comprehensive set of reports that include Tumblr. These reports can be scheduled and exported in CSV and PDF formats.',
    },
  ],
  threads: [
    {
      id: 'pages',
      title: 'Can I directly schedule to multiple Thread Profiles?',
      description:
        'Yes, you can connect as many Thread profiles as you like to your Vista Social account and schedule your posts.',
    },
    {
      id: 'videos',
      title: 'Can I directly schedule video posts to Threads using Vista Social?',
      description:
        'Yes, you can publish single and multiple video posts to Threads profiles right from your Vista Social account.',
    },
    {
      id: 'images',
      title: 'Can I directly schedule image posts to Threads using Vista Social?',
      description:
        'Yes, you can publish single and multiple image posts to Threads profiles right from your Vista Social account.',
    },
    {
      id: 'carousel',
      title: 'Can I directly schedule carousel posts to Threads using Vista Social?',
      description:
        'Yes, you can publish carousel posts that contain a mix of up to 10 video, imageto Threads profiles and profiles right from your Vista Social account. ',
    },
    {
      id: 'comments',
      title: 'Can I respond to comments on Thread Profiles using Vista Social?',
      description: 'Yes, you can respond to comments on posts in your Thread posts using Vista Soical!',
    },
    {
      id: 'comments',
      title: 'Can I generate reports and understand performance of my Thread Profiles using Vista Social?',
      description:
        'Yes, we offer a comprehensive set of reports that include Threads. These reports can be scheduled and exported in CSV and PDF formats.',
    },
  ],
  linkedin: [
    {
      id: 'pages',
      title: 'Can I directly schedule to multiple LinkedIn Company Pages?',
      description:
        'Yes, you can connect as many LinkedIn pages as you like to your Vista Social account and schedule your posts.',
    },
    {
      id: 'profiles',
      title: 'Can I directly schedule to multiple LinkedIn Personal Profiles?',
      description:
        'Yes, you can connect as many LinkedIn personal profiles as you like to your Vista Social account and schedule your posts.',
    },
    BOOST_FAQ,
    {
      id: 'videos',
      title: 'Can I directly schedule video posts to LinkedIn using Vista Social?',
      description:
        'Yes, you can publish video posts to LinkedIn pages and profiles right from your Vista Social account. <a href="https://support.vistasocial.com/hc/en-us/articles/4432388717595--Web-LinkedIn-Publishing-with-Vista-Social">Learn more</a>.',
    },
    {
      id: 'images',
      title: 'Can I directly schedule image posts to LinkedIn using Vista Social?',
      description:
        'Yes, you can publish single image posts to LinkedIn pages and profiles right from your Vista Social account. <a href="https://support.vistasocial.com/hc/en-us/articles/4432388717595--Web-LinkedIn-Publishing-with-Vista-Social">Learn more</a>.',
    },
    {
      id: 'carousels',
      title: 'Can I directly schedule carousel posts to LinkedIn using Vista Social?',
      description:
        'Yes, you can publish carousel posts by using document format (i.e. PDF) to LinkedIn pages and profiles right from your Vista Social account. <a href="https://vistasocial.com/insights/linkedin-pdf-carousels/">Learn more</a>.',
    },
    {
      id: 'mention-company',
      title: 'Can I tag/mention other company pages in my posts scheduled using Vista Social?',
      description:
        'Yes, you can mention other company pages in your posts. <a href="https://support.vistasocial.com/hc/en-us/articles/4432388717595--Web-LinkedIn-Publishing-with-Vista-Social">Learn more</a>.',
    },
    {
      id: 'mention-users',
      title: 'Can I tag/mention other personal profiles in my posts scheduled using Vista Social?',
      description:
        'Yes, you can mention other personal profiles in your page posts for as long as they are a follower of your company page. <a href="https://support.vistasocial.com/hc/en-us/articles/16368186459163-How-to-tag-personal-LinkedIn-profiles-on-page-posts">Learn more</a>.',
    },
    {
      id: 'inbox',
      title: 'Can I respond to messages, comments, mentions and shares using Vista Social?',
      description:
        'While it is not possible to respond to messages, you can definitely respond to comments, mentions and shares using Vista Social. <a href="https://support.vistasocial.com/hc/en-us/articles/4409614773531-Getting-started-with-Inbox">Learn more</a>.',
    },
  ],

  facebook: [
    {
      id: 'multiple',
      title: 'Can I directly schedule to multiple Facebook pages and groups?',
      description:
        'Yes, you can connect as many Facebook pages and groups as you like to your Vista Social account and schedule your posts.',
    },
    {
      id: 'personal',
      title: 'Can I directly schedule Facebook stories?',
      description:
        'Yes, you can schedule Facebook stories using Vista Social and they will be published directly on Facebook. <a href="https://vistasocial.com/insights/can-you-schedule-a-facebook-story/">Learn more</a>.',
    },
    BOOST_FAQ,
    {
      id: 'thumbnail',
      title: 'Can I pick a thumbnail to Videos, Reels and Stories scheduled in Vista Social?',
      description:
        'Yes, you can easily pick a thumbnail for your uploaded video by choosing the best frame from the video itself or by uploading one <a href="https://support.vistasocial.com/hc/en-us/articles/11511396179995-Customizing-Video-Thumbnails-Cover">Learn more</a>.',
    },

    {
      id: 'audio',
      title: 'Can I trending audio for my videos right from Vista Social?',
      description:
        'Yes, you can choose from a library of trending sounds that our team curates or by uploading your own sounds. <a href="https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts">Learn more</a>.',
    },
    {
      id: 'groups',
      title: 'Can I directly schedule to Facebook groups?',
      description:
        'Unfortunately Meta has recently made changes that no longer make publishing directly to Facebook groups. Vista Social users, however, can schedule a post that will be delivered their mobile as a reminder and can then be scheduled on Facebook. <a href="https://support.vistasocial.com/hc/en-us/articles/26557754742683-Facebook-profile-types-explained-Pages-Groups-Personal">Learn more</a>.',
    },
    {
      id: 'comments',
      title: 'Can I schedule comments to be added to my published post?',
      description:
        'Yes, you can add up to 10 additional comments when scheduling a post. They will be added to the post upon publishing. <a href="https://support.vistasocial.com/hc/en-us/articles/6524866650139-Scheduling-a-First-Comment-and-additional-comments">Learn more</a>.',
    },
    {
      id: 'reply-message',
      title: 'Can I reply to Facebook messages and comments from Vista Social?',
      description:
        'Yes, you can reply to messages, comments and reviews that you get on Facebook right from your Vista Social inbox. <a href="https://support.vistasocial.com/hc/en-us/articles/4409614773531-Getting-started-with-Inbox">Learn more</a>.',
    },
    {
      id: 'personal',
      title: 'Can I directly schedule to Facebook personal profiles?',
      description:
        'No, unfortunately due to Meta restrictions personal Facebook profiles are completely off limits for third party looks like Vista Social.',
    },
    {
      id: 'reviews',
      title: 'Can I manage my page reviews in Vista Social?',
      description:
        'Yes, you can track and reply to reviews that your page receives on Facebook right from Vista Social. <a href="https://support.vistasocial.com/hc/en-us/articles/4409607635355-Getting-started-with-review-management-on-Vista-Social">Learn more</a>.',
    },
  ],

  instagram: [
    {
      id: 'multiple',
      title: 'Can I directly schedule to multiple Instagram profiles?',
      description:
        'Yes, you can connect as many Instagram profiles as you like to your Vista Social account and schedule your posts.',
    },
    {
      id: 'reels',
      title: 'Can I directly schedule Instagram Reels with Vista Social?',
      description:
        'Yes, Vista Social offers ability to directly schedule Instagram Reels. No workarounds, no hacks. Schedule your post in Vista Social and it will be published as reel to your Instagram profile. <a href="https://support.vistasocial.com/hc/en-us/articles/7117217001115-Scheduling-Instagram-Reels">Learn more</a>.',
    },
    {
      id: 'stories',
      title: 'Can I schedule Instagram Stories with Vista Social?',
      description:
        'Yes, Vista Social offers ability to schedule and directly publish Stories (images and videos) on Instagram. <a href="https://support.vistasocial.com/hc/en-us/articles/7612460663579-Instagram-Story-Publishing-with-Vista-Social">Learn more</a>.',
    },
    {
      id: 'carousel',
      title: 'Can I directly schedule Instagram Carousel posts with Vista Social?',
      description:
        'Yes, Vista Social offers ability to directly schedule Instagram Carousel. Each post can contain up to 10 images or videos! Posting is direct, no workarounds, no hacks. <a href="https://support.vistasocial.com/hc/en-us/articles/5392214218011-Instagram-direct-publishing-of-carousels-with-images-and-videos">Learn more</a>.',
    },
    {
      id: 'collab',
      title: 'Can I invite collaborators to Instagram posts scheduled in Vista Social?',
      description:
        'Yes, you can invite up to 3 collaborators when scheduled Instagram posts in Vista Social. <a href="https://support.vistasocial.com/hc/en-us/articles/20049112684443--How-to-Add-a-Collaborator-on-Scheduled-Instagram-Posts-in-Vista-Social">Learn more</a>.',
    },
    BOOST_FAQ,
    {
      id: 'user-tagging',
      title: 'Can I tag users in posts scheduled in Vista Social?',
      description:
        'Yes, you can tag Instagram users with public profiles in posts scheduled in Vista Social <a href="https://support.vistasocial.com/hc/en-us/articles/5586961699995-Instagram-user-tagging">Learn more</a>.',
    },
    {
      id: 'product-tagging',
      title: 'Can I tag products in posts scheduled in Vista Social?',
      description:
        'Yes, you can tag products from a connected Instagram shop in both videos and images when you schedule posts in Vista Social <a href="https://support.vistasocial.com/hc/en-us/articles/9265214845467-Tagging-Instagram-Shop-Products">Learn more</a>.',
    },
    {
      id: 'thumbnail',
      title: 'Can I pick a thumbnail to Reels and Stories scheduled in Vista Social?',
      description:
        'Yes, you can easily pick a thumbnail for your uploaded video by choosing the best frame from the video itself or by uploading one <a href="https://support.vistasocial.com/hc/en-us/articles/11511396179995-Customizing-Video-Thumbnails-Cover">Learn more</a>.',
    },
    {
      id: 'audio',
      title: 'Can I trending audio for my videos right from Vista Social?',
      description:
        'Yes, you can choose from a library of trending sounds that our team curates or by uploading your own sounds. <a href="https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts">Learn more</a>.',
    },
    {
      id: 'inbox',
      title: 'Can I respond to Instagram comments and messages from Vista Social?',
      description:
        'Yes, our inbox has all the features you will need to be able to respond to conversations on Instagram',
    },
    {
      id: 'analytics',
      title: 'Do you offer Instagram analytics?',
      description:
        'Yes, Vista Social offers full suite of Instagram reporting that delivers insightful reporting that covers followers, posting, engagement and awareness.',
    },
  ],

  reports: [
    {
      id: 'networks',
      title: 'Which social networks does Vista Social provide analytics for?',
      description:
        'Vista Social provides comprehensive analytics and exportable reporting for all major social networks: Facebook, Instagram, Google Business, TikTok, Twitter, LinkedIn, Pinterest, Reddit, YouTube, Yelp, TrustPilot, TripAdvisor, and OpenTable.',
    },
    {
      id: 'competitor',
      title: "Can I analyze my brand's competitors with Vista Social?",
      description:
        "Yes! Vista Social offers competitor analytics for your Facebook, Instagram, and Twitter profiles. Insightful reporting will provide you with clues about your competitor's marketing strategy!",
    },
    {
      id: 'sentiment',
      title: 'Can I analyze sentiment across comments, messages, mentions and reviews?',
      description:
        'Yes! Vista Social offers comprehensive sentiment analysis, classifying each incoming message, comment, review, or mention as positive, negative, neutral, or mixed and allowing users to generate reports to zoom in on sentiment trends quickly.',
    },

    {
      id: 'export',
      title: 'Can I export analytics to PDF?',
      description: 'Yes! We offer a full suite of export options, including the ability to export to a PDF.',
    },
    {
      id: 'export',
      title: 'Can I export analytics to CSV or Excel?',
      description: 'Yes! We offer a full suite of export options, including the ability to export to a CSV and Excel.',
    },
    {
      id: 'customize',
      title: 'Can I customize my reports?',
      description:
        'Yes, you can build and reuse custom report templates where you can choose the reporting section, add cover pages, and include your branding!',
    },
    {
      id: 'automate',
      title: 'Can I automate report delivery?',
      description:
        'Totally! You can easily schedule reports that will run at your chosen schedule and be delivered to recipients you select.',
    },
    {
      id: 'historical',
      title: 'Is there a historical limit on how much data Vista Social will keep for my brand?',
      description:
        'There is no such limit. All data that we collect for your profile will remain in your account for the duration of you having an account on Vista Social! ',
    },
    {
      id: 'mobile',
      title: 'Can I access analytics on mobile app?',
      description: 'Yes, our mobile app allows users to access reporting.',
    },
  ],

  reviews: [
    {
      id: 'networks',
      title: 'Which networks can I monitor reviews on with Vista Social?',
      description:
        'You can monitor your reviews on Facebook, Google Business, Yelp, TripAdvisor, OpenTable and TrustPilot!',
    },
    {
      id: 'respond',
      title: 'Can I respond to reviews from Vista Social?',
      description:
        "Yes, you can respond to Facebook and Google Business reviews right from your Vista Social dashboard. For other networks a link will be provided to each network's review response page.",
    },
    {
      id: 'reports',
      title: 'Does Vista Social provide review reporting and analytics',
      description:
        'Yes, Vista Social offers comprehensive review analytics that can easily be exported to a PDF or scheduled for an automatic delivery.',
    },
  ],

  inbox: [
    {
      id: 'facebook',
      title: 'Can I respond to Facebook messages and comments?',
      description:
        'Yes, you will see all of your Facebook messages and comments in your inbox with ability to quickly and easily respond to them!',
    },
    {
      id: 'instagram',
      title: 'Can I respond to Instagram messages and comments?',
      description:
        'Yes, you will see all of your Instagram messages and comments in your inbox with ability to quickly and easily respond to them!',
    },
    {
      id: 'tiktok',
      title: 'Can I respond to TikTok comments?',
      description: 'Yes! You can respond to TikTok comments!',
    },
    {
      id: 'youtube',
      title: 'Can I respond to YouTube comments?',
      description: 'Yes! You can respond to YouTube comments!',
    },
    {
      id: 'linkedin',
      title: 'Can I respond to LinkedIn comments?',
      description: 'Yes! You can respond to LinkedIn comments!',
    },
    {
      id: 'tagging',
      title: 'Can I tag important conversations?',
      description:
        'Yes! You can easily add labels to existing conversations which makes it ever more convenient to find them later!',
    },
  ],

  publish: [
    INSTAGRAM_FAQ,
    TIKTOK_FAQ,
    FACEBOOK_FAQ,
    BOOST_FAQ,
    {
      id: 'shortener',
      title: 'What URL shorteners does Vista Social support?',
      description:
        'We offer a built-in shortener using our vist.ly domain, and you may also white label it using your domain. If you are currently using bit.ly, you may use it as well!',
    },
    {
      id: 'assets',
      title: 'Can I store my images and videos in Vista Social?',
      description:
        'Yes! The Vista Social media Library allows you to store images and videos. You can group your assets by clients or campaigns with labels to easily find all your content.',
    },
    {
      id: 'export',
      title: 'Can I export published and schedule posts?',
      description:
        'Yes! You can easily <a href="https://vistasocial.com/insights/shared-calendar-the-ultimate-social-media-content-planner/">create shareable calendar</a> links or create amazing looking PDFs',
    },
    {
      id: 'besttime',
      title: 'Does Vista Social have publishing time recommendations?',
      description:
        'Yes! We offer best time to publish analytics to our users that you can easily access at the time of publishing!',
    },
    MOBILE_FAQ,
    BULK_FAQ,
  ],

  enterprise: [
    {
      id: 'shortener',
      title: 'What URL shorteners does Vista Social support?',
      description:
        'We offer a built-in shortener using our vist.ly domain, and you may also white label it using your domain. If you are currently using bit.ly, you may use it as well!',
    },
  ],

  all: [
    {
      id: 'what',
      title: 'What is Vista Social?',
      description:
        'Vista Social is a social media management and optimization platform for brands and agencies of all sizes. Our platform gives you a single hub for social media publishing, analytics and engagement across all of your social profiles.',
    },
    {
      id: 'who',
      title: 'Who uses Vista Social?',
      description:
        'Vista Social is used by brands and marketing agencies around the world, in industries ranging from education, hospitality, fashion, tech, digital marketing agencies and everything in between. ',
    },
    {
      id: 'how',
      title: 'How does the free trial work?',
      description:
        'Your free trial lets you explore Vista Social with no credit card needed for 14 days! You can subscribe to a monthly or an annual plan at any time during the trial or once it ends.',
    },
    {
      id: 'pros',
      title: 'What are the pros of Vista Social?',
      description:
        'There are plenty of benefits to using Vista Social such as the ability to manage all your social profiles from a single platform, in-depth analytics to understand how your brand is performing on social and an easy way to collaborate with coworkers or clients. We are constantly growing and adding new features that make it easier for our customers to communicate and engage with their audiences on social.',
    },
    {
      id: 'is_free',
      title: 'Is Vista Social free?',
      description:
        'We do offer a completely free version intended for those that are just getting started on social media. This free version offers the essentials of running your social media such as publishing, reporting, and reviews.',
    },
    {
      id: 'demo',
      title: 'How do I request a demo of Vista Social?',
      description:
        'Sometimes a call isn’t enough. If you’re interested in a more personalized video overview to get started with Vista Social, we’re more than happy to meet you that way. You can <a href="https://vistasocial.com/demo/">set that up here</a>!',
    },

    {
      id: 'features',
      title: 'What are Vista Social’s features?',
      description:
        'Vista Social provides a variety of features including tools for analytics, engagement, publishing, monitoring and listening.',
    },
    {
      id: 'reports',
      title: 'Can I create social media reports with Vista Social?',
      description:
        'Yes, you can create and send unlimited presentation-ready reports in Vista. With Vista Social’s suite of reports, your brand can track metrics related to audience growth, reach, impressions, engagement and more. Also, with our Report Builder you can create custom reports with the metrics most important to your brand.',
    },
    INSTAGRAM_FAQ,
    FACEBOOK_FAQ,
    TIKTOK_FAQ,

    {
      id: 'inbox',
      title: 'What is the Inbox?',
      description:
        'The Inbox gives you visibility into your incoming social media messages across Facebook, Twitter, LinkedIn, Instagram and Google Business, all in a single stream. You can use the Smart Inbox to monitor, reply and filter through messages.',
    },
    {
      id: 'training',
      title: 'Do you offer training?',
      description:
        'Yes, we host weekly webinars for teams where we walk the users through the platform and answer questions.',
    },
    {
      id: 'support',
      title: 'How does the support work?',
      description:
        'You can always get help right from your dashboard by using the “?” help button. Our fanatical support team will make every effort possible to help you. If necessary, the support team can be available via phone, video call, skype, and many other forms of communication.',
    },
    {
      id: 'browser',
      title: 'Is there a Vista Social browser extension?',
      description:
        'Yes. You can download our <a href="https://chrome.google.com/webstore/detail/vista-social/penkaabfmokbebopkikfcoobplgbncjc?hl=en&authuser=0">Google Chrome</a> and <a href="https://addons.mozilla.org/en-US/firefox/addon/vista-social/">Firefox</a> extensions to share content from around the web.',
    },
    MOBILE_FAQ,
  ],

  vista_page: [
    {
      id: 'what',
      title: 'What is a Vista Page and why is it useful?',
      description:
        'Vista Page, a mobile friendly micro site that is perfect to link to from your social networks. Its simplicity ensures that your followers can easily find the most important information about your brand without having to navigate through complex website.',
    },

    {
      id: 'diff',
      title: 'Is Vista Page a link in bio tool?',
      description:
        'Yes, but it is a lot more than just a link that you can insert a bio section on your social profiles. It is a complete website, complete with endless customizations and ability to extend it with plugins and features.',
    },
    {
      id: 'why',
      title: 'Why should I link to a Vista Page from my social profiles?',
      description:
        'Since many networks do not allow links except for the one in your profile, it is important that that one link is optimized! A <a href="https://www.parse.ly/link-in-bio-providers-add-10-15-to-instagram-referral-traffic/" target="_blank">Parse.ly</a> study found that adding a link in bio to your Instagram profile can drive an additional 10-15 percent Instagram referral traffic to your landing page.',
    },
    {
      id: 'limit',
      title: 'Is there a limit on how many Vista Pages I can create?',
      description:
        'There is no limit on the number of Vista Pages you can create. Each page will count as a profile, so you just need to make sure you have enough profiles on your subscription.',
    },
    {
      id: 'publish',
      title: 'Can I schedule posts to Vista Page?',
      description: 'Yes! Your Vista Pages will be available to schedule content to just like any other social profile!',
    },
    {
      id: 'reports',
      title: 'Can I analyze the performance of my Vista Page?',
      description:
        'Yes! We have a built in report that will tell you how often your pages are viewed and which links visitors click! You can take this much further though by connecting your Google Analytics to Vista Page and get full power of Google Analytics for reporting! ',
    },
    {
      id: 'domain',
      title: 'Can I use my own domain?',
      description:
        'Yes! You can either use a yourname.vista.page or configure your very own domain. SSL certificates are included with your custom domain installation.',
    },
    {
      id: 'payments',
      title: 'Can I use a Vista Page to collect payments?',
      description:
        'Yes! You can easily integrate your favorite payment system (i.e. PayPal, Stripe, etc) using Google Tag manager integration.',
    },
    {
      id: 'forms',
      title: 'Can I collect signups using Vista Page?',
      description:
        'Yes! You can easily integrate your favorite forms app (i.e. Typeform, Google Forms, etc) using Google Tag manager integration.',
    },
  ],
}

export const { ANALYTICS, ENGAGEMENT, LISTENING, PUBLISHING, REVIEWS } = {
  ANALYTICS: 'analytics',
  ENGAGEMENT: 'engagement',
  LISTENING: 'listening',
  PUBLISHING: 'publishing',
  REVIEWS: 'reviews',
}

export const {
  SITE_LINK_PUBLISHING,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_ENGAGEMENT,
  SITE_LINK_INTEGRATIONS,
  SITE_LINK_CUSTOMER_STORIES,
  SITE_LINK_AI,
  SITE_LINK_MOBILE_APP,
  SITE_LINK_SOLUTIONS_AGENCIES,
  SITE_LINK_SOLUTIONS_CREATORS,
  SITE_LINK_SOLUTIONS_SMALL_BUSINESSES,
  SITE_LINK_SOLUTIONS_ENTERPRISE,
  SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS,
  SCHEDULE_DEMO_LINK,
  SITE_LINK_VISTA_PAGE,
  SITE_LINK_BOOSTING,
  SITE_LINK_EMPLOYEE_ADVOCACY,
  SITE_LINK_ADVOCACY,
} = {
  SITE_LINK_BOOSTING:
    'https://support.vistasocial.com/hc/en-us/articles/23687755431835-Boosting-Posts-with-Vista-Social',
  SITE_LINK_PUBLISHING: '/social-media-publishing/',
  SITE_LINK_ANALYTICS: '/social-media-analytics/',
  SITE_LINK_LISTENING: '/social-media-listening/',
  SITE_LINK_REVIEWS: '/review-management/',
  SITE_LINK_ADVOCACY: '/employee-advocacy',
  SITE_LINK_VISTA_PAGE: '/vista-page/',
  SITE_LINK_ENGAGEMENT: '/social-media-engagement/',
  SITE_LINK_INTEGRATIONS: '/integrations/',
  SITE_LINK_CUSTOMER_STORIES: '/customers/',
  SITE_LINK_AI: '/ai-assistant/',
  SITE_LINK_MOBILE_APP: '/mobile/',
  SITE_LINK_SOLUTIONS_AGENCIES: '/agencies/',
  SITE_LINK_SOLUTIONS_CREATORS: '/creators/',
  SITE_LINK_SOLUTIONS_SMALL_BUSINESSES: '/small-medium-businesses/',
  SITE_LINK_SOLUTIONS_ENTERPRISE: '/enterprise/',
  SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS: '/multi-location-brands/',
  SCHEDULE_DEMO_LINK: '/demo/',
  SITE_LINK_EMPLOYEE_ADVOCACY: '/employee-advocacy/',
}

export const { DOWNLOAD_APP_GOOGLE_LINK, DOWNLOAD_APP_APPLE_LINK } = {
  DOWNLOAD_APP_GOOGLE_LINK: 'https://play.google.com/store/apps/details?id=com.vistasocial.android',
  DOWNLOAD_APP_APPLE_LINK: 'https://apps.apple.com/us/app/vista-social/id1623203443',
}

export const AUTHORS = {
  list: [
    {
      name: 'Sabreen Haziq',
      title: 'Head of Marketing @ Vista Social',
    },
    {
      name: 'Brittany Garlin',
      title: 'Marketing @ Vista Social',
    },
    {
      name: 'Alexus Brittain',
      title: 'Head of Social Media @ Vista Social',
    },
    {
      name: 'Reggie Azevedo',
      title: 'Head of Customer Success @ Vista Social',
    },
  ],
  getTitle: (name) => {
    const author = AUTHORS.list.find((item) => item.name === name)

    return author && author.title ? author.title : 'Content Writer'
  },
}
